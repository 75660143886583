<template>
  <div>
    <div v-if="open" class="overlay" :class="{ 'overlay-on': open }" aria-hidden="true" @click.stop="close" />
    <div ref="sheet" class="sheet" :class="{ open: open }" :aria-hidden="!open" @click.stop="onTapNav">
      <div class="nub-container"><div class="nub"></div></div>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { watch, onUnmounted, useTemplateRef } from 'vue'
import { ref, computed } from 'vue'
import { usePointerSwipe } from '@vueuse/core'

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

let scrollPosition = 0
const positionY = ref('0')
const sheet = useTemplateRef('sheet')

const sheetHeight = computed(() => sheet.value?.offsetHeight)

const { distanceY } = usePointerSwipe(sheet, {
  onSwipe() {
    if (distanceY.value > 0) return
    positionY.value = Math.abs(distanceY.value) + 'px'
  },
  onSwipeEnd(_e, direction) {
    if (direction !== 'down') return
    if (Math.abs(distanceY.value) / sheetHeight.value >= 0.3) {
      close()
    } else {
      positionY.value = '0'
    }
  },
})

function lockBodyScroll() {
  scrollPosition = window.scrollY
  document.body.style.position = 'fixed'
  document.body.style.top = `-${scrollPosition}px`
  document.body.style.width = '100%'
  document.body.style.overflow = 'hidden'
  // Prevent pull-to-refresh in Safari
  document.documentElement.style.overscrollBehavior = 'none'
}

function unlockBodyScroll() {
  document.body.style.position = ''
  document.body.style.top = ''
  document.body.style.width = ''
  document.body.style.overflow = ''
  document.documentElement.style.overscrollBehavior = ''
  window.scrollTo(0, scrollPosition)
}

function onTapNav(e) {
  if (e.target.tagName === 'A') {
    close()
  }
}

function close() {
  positionY.value = '0'
  emit('close')
}

watch(
  () => props.open,
  () => {
    if (props.open) lockBodyScroll()
    else unlockBodyScroll()
  }
)

onUnmounted(unlockBodyScroll)
</script>

<style scoped>
.overlay {
  background: var(--color-black);
  inset: 0;
  position: fixed;
  opacity: 0;
}

.overlay-on {
  opacity: var(--opacity-overlay);
  z-index: 1;
}

.sheet {
  position: fixed;
  min-height: calc(50vh + var(--main-nav-height));
  max-height: 90vh;
  transition-property: background-color, transform;
  border-radius: 16px 16px 0 0;
  background-color: var(--color-nav-sheet);
  bottom: var(--main-nav-height);
  left: 0;
  right: 0;
  transform: translateY(calc(100% + 76px));
  z-index: 1;
}

.nub-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.nub {
  height: 4px;
  width: 45px;
  border-radius: var(--border-radius);
  background-color: var(--color-border);
}

.sheet.open {
  transform: translateY(v-bind('positionY'));
}
</style>
