import { computed } from 'vue'
import { viewer } from '@/viewer.js'

const loggedOutfeatures = computed(() => ({}))

const features = computed(() => {
  return viewer.value?.activeMembership?.feature_flags || {}
})

/** @param {string} feature */
export function featureIsOn(feature) {
  return features.value[feature] || false
}

/** @param {string} feature */
export function loggedOutfeatureIsOn(feature) {
  return loggedOutfeatures.value[feature] || false
}
