<template>
  <nav class="navlist">
    <ExpandableSection :open="open" @toggle="onToggle">
      <template #header>
        <Row class="header" gap="sm">
          <slot name="icon"></slot>
          <span>{{ headerLabel }}</span>
        </Row>
      </template>
      <template #content>
        <slot name="navItems"></slot>
      </template>
    </ExpandableSection>
  </nav>
</template>

<script setup>
import { toRefs } from 'vue'
import { ExpandableSection, Row } from '@justworkshr/alma'
import { useStorage } from '@vueuse/core'

const props = defineProps({
  headerLabel: {
    type: String,
    required: true,
  },
})

const { headerLabel } = toRefs(props)

const open = useStorage(`nav-${headerLabel.value}`, true)

function onToggle(event) {
  open.value = event.target.open
}
</script>

<style scoped>
.navlist {
  padding: 0;
  margin-inline: var(--space-sm);
  margin-bottom: var(--space-sm);
  overflow: auto;
  max-height: 50vh;
  @media (--tablet) {
    display: block;
    max-height: 90vh;
  }
}

.header {
  padding: var(--space-sm);
  display: flex;
  align-items: center;
  transition: background-color 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1;
  border-radius: var(--border-radius);
  white-space: nowrap;
}

.navlist :deep(a) {
  padding: 0.75rem var(--space-lg) 0.75rem 2.25rem;
  display: block;
  transition: background-color 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1;
  border-radius: var(--border-radius);
  white-space: nowrap;
}

.navlist :deep(*):focus-visible {
  outline-offset: -2px;
}

.navlist :deep(*):hover {
  text-decoration: none;
  background-color: transparent;
}

.navlist :deep(a):not(.router-link-active):hover {
  background-color: var(--color-nav-hover);
  cursor: pointer;
}

:deep(.icon) {
  font-size: 1.25rem;
  color: var(--nav-icon-color);
}

:deep(.router-link-active),
:deep(.router-link-active):hover {
  position: relative;
  background-color: var(--nav-active-background-color, --color-blue-100);
}

:deep(.router-link-active)::before {
  background-color: var(--nav-active-border-color);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
}

:deep(summary) {
  align-items: center;
}

:deep(details[open] + .content) {
  margin-top: 0;
}

:deep(details[open] .header) {
  font-weight: bold;
}

:deep(summary > .icon) {
  margin-right: var(--space-sm);
  color: transparent;
}

:deep(summary:is(:focus-visible, :hover) > .icon) {
  color: var(--nav-icon-color);
}
</style>
