<template>
  <div>
    <PayrollGhostBanner v-if="viewer?.user_id.endsWith('admin-user')" />

    <div class="grid-container">
      <template v-if="xm">
        <PayrollMainNavXM v-if="tablet || desktop" class="nav" />
        <PayrollMobileNavXM v-else class="nav" />
      </template>

      <template v-else>
        <PayrollMainNav v-if="tablet || desktop" class="nav" />
        <PayrollMobileNav v-else class="nav" />
      </template>

      <main class="main">
        <router-view :key="$route.name === 'dashboard' ? $route.fullPath : ''" />
      </main>
      <PayrollFooter class="footer" />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, inject } from 'vue'
import useBreakpoint from '@/use/useBreakpoint.js'
import { viewer } from '@/viewer.js'
import {
  PayrollMainNav,
  PayrollMainNavXM,
  PayrollMobileNav,
  PayrollFooter,
  PayrollMobileNavXM,
} from '../components/payroll'

const PayrollGhostBanner = defineAsyncComponent(() => import('../components/payroll/PayrollGhostBanner.vue'))

const { tablet, desktop } = useBreakpoint()

const xm = inject('xm', false)
</script>

<style scoped>
.grid-container {
  background-color: var(--color-page-background);
  display: grid;
  grid-template-areas:
    'main'
    'footer'
    'main-nav';
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  @media (--tablet) {
    height: 100%;
    background: var(--color-page-background);
    grid-template-rows: 1fr 4.5rem;
    grid-template-columns: 256px minmax(512px, 1024px); /* Show the side nav for non-mobile screens */
    grid-template-areas:
      'main-nav main .'
      'main-nav footer .';
  }
}

.nav {
  --color-nav-default: var(--color-gray-100);
  --color-nav-translucent: var(--color-gray-100-60);
  --color-nav-hover: var(--color-gray-200);
  --color-nav-sheet: var(--color-gray-100);
}

.dark .nav {
  --color-nav-default: var(--color-navy);
  --color-nav-translucent: var(--color-navy-60);
  --color-nav-hover: var(--color-navy-400);
  --color-nav-sheet: var(--color-navy-600);
}

.main {
  grid-area: main;
  background-color: var(--color-page-background);
  padding: var(--space-lg) var(--space-md);
  min-height: 100vh;
  max-width: 100vw;
  @media (--tablet) {
    padding: var(--space-3xl);
  }
}

.footer {
  grid-area: footer;
  background: var(--color-footer-background);
  padding: var(--space-sm) var(--space-md);
  padding-bottom: calc(var(--main-nav-height) + env(safe-area-inset-bottom) + var(--space-md));
  @media (--tablet) {
    padding: var(--space-md) var(--space-3xl);
  }
}
</style>
