<script setup>
import { computed } from 'vue'
import { Stack } from '@justworkshr/alma'
import { integrationConfigs } from '../../../data/configs/config.js'

const props = defineProps({
  partner_slug: {
    type: String,
    required: true,
  },
})

const tabContent = computed(() => integrationConfigs[props.partner_slug].pdp.infoTabsContent.dataAndPermissionsTab)
</script>

<template>
  <Stack>
    <h2>{{ tabContent?.dataSharedSection.header }}</h2>

    <Stack gap="xs">
      <div v-for="group in tabContent?.dataSharedSection.sharedDataGroups" :key="group.header">
        <div>{{ group.header }}</div>
        <ul>
          <li v-for="item in group.sharedData" :key="item">{{ item }}</li>
        </ul>
      </div>
    </Stack>
    {{ tabContent?.footnote }}
    {{ tabContent?.notice }}
    <hr />
    <h3>
      {{ tabContent.permissionsSection.header }}
    </h3>

    <div v-for="item in tabContent.permissionsSection.list" :key="item">
      <h3>{{ item.summary }}</h3>
      <span v-safe-html="item.detail" />
    </div>
  </Stack>
</template>
