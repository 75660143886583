import { computed, watch } from 'vue'
import useApiFetch from '@/use/useApiFetch.js'
import { viewer } from '@/viewer.js'

function createWatchedPromise(data, currStatusCode, failureStatusCode) {
  return new Promise((resolve) => {
    watch(data, () => {
      resolve(!!data.value)
    })
    watch(currStatusCode, () => {
      if (currStatusCode.value === failureStatusCode) resolve(false)
      else resolve(true)
    })
  })
}

/**
 * Check if the value is available while registering.
 *
 * @param {string} key
 * @param {string} value
 * @returns {Promise<boolean>}
 */
export function preCheckRegister(key, value) {
  const { data, post, statusCode } = useApiFetch('/data/register/precheck', {}, false)
  post({ [key]: value })
  return createWatchedPromise(data, statusCode, 409)
}

/**
 * Check if the work email is available while inviting.
 *
 * @param {string} value
 * @returns {Promise<boolean>}
 */
export function preCheckInvite(value) {
  const url = computed(() => `/data/companies/${viewer.value.activeCompany.company_id}/invite/precheck`)
  const { data, post, statusCode } = useApiFetch(url, {}, false)
  post({ work_email: value })
  return createWatchedPromise(data, statusCode, 409)
}

/**
 * Check if the routing number is real when creating a bank account
 *
 * @param value
 * @returns {Promise<unknown>}
 */

export function preCheckRoutingNumber(value) {
  const url = computed(() => '/data/bank-accounts/routing-number/precheck')
  const { data, post, statusCode } = useApiFetch(url, {}, false)
  post({ routing_number: value })
  return createWatchedPromise(data, statusCode, 404)
}
