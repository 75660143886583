<template>
  <SubNav :title="$t('Your stuff')">
    <WorkspaceLink to="/account/settings/basic-information" name="profile">
      {{ $t('Profile') }}
    </WorkspaceLink>
    <WorkspaceLink v-if="memberTypesForFeature.has_compensation.has(memberType)" to="/account/paystubs" name="pay">
      {{ $t('Pay') }}
    </WorkspaceLink>
    <WorkspaceLink v-if="showTimeTrackingURL" :to="timeTrackingURL" name="timecards">
      {{ $t('Timecards') }}
    </WorkspaceLink>
    <WorkspaceLink
      v-if="memberTypesForFeature.pto.has(memberType) && !isTerminated"
      to="/account/time-off"
      name="time-off"
    >
      {{ $t('Time off') }}
    </WorkspaceLink>
    <WorkspaceLink v-if="sageMemberId" :to="benefitsSSOLink" :title="benefitsSSOLink" name="benefits">
      {{ $t('Benefits') }}
    </WorkspaceLink>
    <WorkspaceLink to="/account/documents" name="documents">
      {{ $t('Documents') }}
    </WorkspaceLink>
    <WorkspaceLink to="/account/settings/security" name="login-and-security">
      {{ $t('Login & security') }}
    </WorkspaceLink>
    <WorkspaceLink to="/account/settings/preferences" name="preferences">
      {{ $t('Preferences') }}
    </WorkspaceLink>
  </SubNav>
</template>

<script setup>
import { computed } from 'vue'
import WorkspaceLink from '@/components/WorkspaceLink.vue'
import { getTimeTrackingURL } from '@/data/integrations.js'
import { memberTypesForFeature } from '@/data/memberTypes.js'
import { viewer } from '@/viewer.js'
import SubNav from './SubNav.vue'

const member = computed(() => viewer.value.activeMembership.member)

const memberType = computed(() => member.value?.member_type || '')

const isTerminated = computed(() => Boolean(viewer.value.activeMembership.member.member_status === 'terminated'))

const sageMemberId = computed(() => viewer.value?.activeMembership.member.sage_member_id || '')

const sageIntegrationHost = import.meta.env.VITE_APP_SAGE_INTEGRATION_BASE_URL

const benefitsSSOLink = computed(() =>
  sageIntegrationHost && member.value?.benefits_id
    ? `${sageIntegrationHost}?target_member_id=${member.value?.benefits_id}`
    : ''
)

const hasTimeTrackingIntegration = computed(
  () => viewer.value?.activeCompany.timetracking_integration_status === 'active'
)

const showTimeTrackingURL = computed(
  () =>
    hasTimeTrackingIntegration.value &&
    member.value?.flsa_exempt_status !== 'exempt' &&
    ['hourly', 'annual'].includes(member.value?.pay_basis)
)

const timeTrackingURL = computed(() => (showTimeTrackingURL.value ? getTimeTrackingURL() : ''))
</script>
