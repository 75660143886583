//@ts-check
import { computed } from 'vue'
import { memberTypesForFeature } from '@/data/memberTypes.js'
import { featureIsOn } from '@/features.js'
import { t } from '@/i18n'
import useNavPermissions from '@/use/useNavPermissions.js'
import { viewer, viewerCan, isTerminated } from '@/viewer.js'

// @ts-ignore
const sageIntegrationHost = import.meta.env.VITE_APP_SAGE_INTEGRATION_BASE_URL
// @ts-ignore
const expensesHost = import.meta.env.VITE_APP_EXPENSES_BASE_URL

/** @returns {import('vue').ComputedRef<PeoNavGroup[]>} */
export default function usePeoNavGroups() {
  const { showNavPayments, showNavManage, showNavAnalytics, showEOY, showHR } = useNavPermissions()
  const isVendor = computed(() => viewer.value?.activeMembership.feature_flags.neonmoose_global_vendors)
  const companyId = computed(() => viewer.value?.activeCompany.company_id)
  const sageMemberId = computed(() => viewer.value?.activeMembership.member.sage_member_id)
  const benefitsId = computed(() => viewer.value?.activeMembership.member.benefits_id)
  const benefitsSSOLink = computed(() => `${sageIntegrationHost}admin?target_member_id=${benefitsId.value}`)
  const integrationsLink = computed(() =>
    featureIsOn('neonmoose_global_one_justworks_marketplace')
      ? '/integrations/marketplace'
      : '/cf/integration-marketplace'
  )

  return computed(() => [
    {
      groupName: t('Unlock all Justworks features!'),
      callout: true,
      visible: isVendor.value,
      iconless: true,
      path: '/more-services',
      key: 'vendor_company',
    },
    {
      groupName: t('End of Year Task List'),
      callout: true,
      visible: !isTerminated.value && showEOY.value && !isVendor.value,
      path: '/eoy',
      key: 'eoy',
    },
    {
      groupName: t('Expand globally!'),
      callout: true,
      calloutVariant: 'box',
      secondaryCalloutText: t('Manage international and US teams, all in one platform'),
      visible: viewerCan('edit_company_settings', 'make_payments', 'pay_contractors_and_vendors'),
      path: '/international-solutions/overview',
      key: 'international',
    },
    {
      groupName: t('Payments'),
      key: 'payments',
      visible: !isTerminated.value && showNavPayments.value && !isVendor.value,
      navItems: [
        {
          hidden: !viewerCan('manage_company_benefits'),
          altPath: `${expensesHost}/ccid/${companyId.value}/review`,
          displayText: t('Expenses'),
          badge: {
            type: 'new',
            text: t('New'),
          },
        },
        {
          path: '/cf/pay-transactions',
          displayText: t('Transactions'),
        },
        {
          path: '/payments/payments-center',
          displayText: t('Payments center'),
        },
        {
          path: `/payments/${companyId.value}/timecards`,
          displayText: t('Timecards'),
        },
        {
          path: `/payments/${companyId.value}/payrolls`,
          displayText: t('Invoices'),
        },
        {
          path: '/tax_credits',
          displayText: t('R&D tax credit'),
          hidden: true,
        },
      ],
    },
    {
      groupName: t('HR'),
      key: 'hr',
      visible: !isTerminated.value && showHR.value && !isVendor.value,
      navItems: [
        {
          path: `/company/${companyId.value}/benefits`,
          altPath: viewerCan('manage_company_benefits') && sageMemberId.value ? benefitsSSOLink.value : null,
          displayText: t('Benefits'),
        },
        {
          path: '/bulk-actions',
          displayText: t('Bulk updates'),
        },
        {
          path: `/company/${companyId.value}/time-off`,
          displayText: t('Time off'),
        },
        {
          path: '/admin/trainings',
          displayText: t('Training'),
        },
        {
          path: `/company/${companyId.value}/documents`,
          displayText: t('Documents'),
        },
        {
          path: '/help/hr_resources',
          displayText: t('Resources'),
        },
      ],
    },
    {
      groupName: t('Manage'),
      key: 'manage',
      visible: !isTerminated.value && showNavManage.value && !isVendor.value,
      navItems: [
        {
          path: `/manage/${companyId.value}/employees`,
          displayText: t('Employees'),
        },
        {
          path: `/manage/${companyId.value}/contractors`,
          displayText: t('Contractors'),
        },
        {
          path: '/members/vendors',
          displayText: t('Vendors'),
        },
        {
          path: `/manage/${companyId.value}/third-party-admins`,
          displayText: t('Third party'),
        },
      ],
    },
    {
      groupName: t('Reporting'),
      key: 'analytics',
      visible: !isTerminated.value && showNavAnalytics.value && !isVendor.value,
      navItems: [
        {
          path: '/cf/analytics/insights',
          displayText: t('Insights'),
          badge: {
            type: 'new',
            text: t('New'),
          },
        },
        {
          path: '/cf/analytics/reporting',
          displayText: t('Reporting'),
        },
      ],
    },
    {
      groupName: t('Company'),
      key: 'company',
      visible: !isTerminated.value,
      navItems: [
        {
          path: `/company/${companyId.value}/directory`,
          displayText: t('Directory'),
          hidden: isVendor.value,
        },
        {
          path: '/directory/org_chart',
          displayText: t('Org chart'),
          hidden: isVendor.value,
        },
        {
          path: '/company/calendar',
          displayText: t('Calendar'),
          hidden: isVendor.value,
        },
        {
          path: integrationsLink.value,
          displayText: t('Integrations'),
          hidden: isVendor.value,
        },
        {
          path: '/admin/documents',
          displayText: t('Documents'),
          hidden: !isVendor.value,
        },
        {
          path: `/company/${companyId.value}/settings`,
          displayText: t('Settings'),
        },
        {
          path: '/custom_member_fields',
          displayText: t('Custom fields'),
          hidden: isVendor.value,
        },
        {
          path: '/permissions',
          displayText: t('Permissions'),
          hidden: isVendor.value,
        },
        {
          path: '/company_benefits_overviews',
          displayText: t('Benefits overview'),
          hidden: isVendor.value,
        },
      ],
    },
    {
      groupName: t('You'),
      key: 'you',
      visible: true,
      navItems: [
        {
          path: '/vouchers',
          displayText: t('Paystubs'),
          hidden: isVendor.value,
        },
        {
          path: '/account/time-off',
          displayText: t('Time off'),
          hidden:
            isTerminated.value ||
            !memberTypesForFeature.pto.has(viewer.value?.activeMembership.member.member_type) ||
            isVendor.value,
        },
        {
          path: '/my_benefits',
          displayText: t('Benefits'),
          hidden: isVendor.value,
        },
        {
          path: '/account/documents',
          displayText: t('Documents'),
        },
        {
          path: '/training',
          displayText: t('Training'),
          hidden: isTerminated.value || isVendor.value,
        },
        {
          path: '/account/settings',
          displayText: t('Account settings'),
        },
      ],
    },
    {
      groupName: '',
      visible: true,
      key: 'refer',
      navItems: [
        {
          path: '/refer',
          displayText: t('Refer & Earn'),
          badge: {
            type: 'new',
            text: t('New'),
          },
        },
      ],
    },
  ])
}
