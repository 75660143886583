<template>
  <nav class="site-nav" :aria-label="$t('Site')">
    <div class="logo-area">
      <Row gap="md" class="logo-row">
        <PageLink to="/dashboard" class="logo-j">
          <Logo height="20px" />
        </PageLink>
        <CompanySelector v-if="Object.keys(viewer.companies).length > 1" class="company-selector" />
        <PageLink v-else to="/dashboard" class="h4 company-name">
          {{ viewer.activeCompany.dba || viewer.activeCompany.entity_name }}
        </PageLink>
      </Row>
    </div>
    <template v-if="!$route.meta.navless">
      <nav ref="nav" class="main-nav" :aria-label="$t('Main')">
        <CurrentSubNav />
      </nav>

      <UserMenu class="member-section" :offset="17">
        <template #button>
          <Row>
            <PageLink :to="`/account/settings/profile-photo`" :title="$t('Edit profile photo')">
              <MemberAvatar
                :image-url="member?.profile_picture_url"
                :first-name="member?.preferred_name || member?.first_name"
                :last-name="member?.last_name"
                size="2.5rem"
                editable
              />
            </PageLink>
            <b>{{ formatMemberName(member) || $t('User') }}</b>
          </Row>
        </template>

        <template #popover>
          <PageLink :to="`/account/settings/basic-information`">
            <Row gap="md">
              <IconEye width="20px" height="20px" color="var(--color-text-secondary)" />
              {{ $t('Profile ') }}
            </Row>
          </PageLink>

          <PageLink :to="`/account/settings/security`">
            <Row gap="md">
              <IconGear width="20px" height="20px" color="var(--color-text-secondary)" />
              {{ $t('Settings') }}
            </Row>
          </PageLink>

          <Row style="padding-bottom: var(--space-sm)">
            <Row gap="md">
              <IconMoon width="20px" height="20px" color="var(--color-text-secondary)" />
              {{ $t('Dark mode') }}
            </Row>
            <AppearanceSwitch />
          </Row>

          <Row style="padding-top: var(--space-sm); padding-bottom: var(--space-sm)">
            <Row gap="md">
              <IconGlobe width="20px" height="20px" color="var(--color-text-secondary)" />
              {{ $t('Language') }}
            </Row>
            <FlexSpace />
            <LanguageSelector style="width: 42px; height: 32px" />
          </Row>

          <PageLink :to="`/logout`">
            <Row gap="md">
              <IconLogout width="20px" height="20px" color="var(--color-text-secondary)" />
              {{ $t('Log out') }}
            </Row>
          </PageLink>
        </template>
      </UserMenu>
    </template>
  </nav>
</template>

<script setup>
import { computed, ref } from 'vue'
import { AppearanceSwitch, FlexSpace, MemberAvatar, Row } from '@justworkshr/alma'
import Logo from '@/assets/justworks-logo-j.svg'
import CompanySelector from '@/components/CompanySelector.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import PageLink from '@/components/PageLink.vue'
import { formatMemberName } from '@/format.js'
import { viewer } from '@/viewer.js'
import IconEye from '~icons/ph/eye'
import IconGear from '~icons/ph/gear'
import IconGlobe from '~icons/ph/globe'
import IconMoon from '~icons/ph/moon'
import IconLogout from '~icons/ph/sign-out'
import CurrentSubNav from './CurrentSubNav.vue'
import UserMenu from './UserMenu.vue'

const nav = ref(null)
const member = computed(() => viewer.value.activeMembership && viewer.value.activeMembership.member)
</script>

<style scoped>
.site-nav {
  --height-logo: 80px;
  --height-active-company: 46px;
  --height-active-member: 212px;
  --height-member-section: 80px;

  grid-area: main-nav;
  background-color: var(--color-nav-default);
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo-area {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(8px);
  height: var(--height-logo);
  z-index: 1;
  padding: 0.75rem;
}

.logo-row {
  padding: var(--space-md);
  width: 100%;
  height: 100%;
}

.main-nav {
  position: absolute;
  inset: 0;
  overflow-y: hidden;
  padding-inline: 0.75rem;
  padding-top: var(--height-logo);
  padding-bottom: calc(var(--height-member-section) + var(--space-md));
}

.main-nav:hover {
  overflow-y: auto;
}

.logo-j {
  display: flex;
}

.company-name {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.1em; /* ensure descenders don't get cut off by the hidden overflow */
}

.company-selector :deep(select) {
  font-weight: bold;
}

.member-section {
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
}
</style>
