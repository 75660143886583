// @ts-check
import HeroImg from '@/assets/integrations/hero/carta-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/carta-horizontal.svg'
import Logo from '@/assets/integrations/logo/carta-logo.svg'
import { t } from '@/i18n.js'
import MdiExternalLink from '~icons/mdi/external-link'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { genGetLegalDisclaimerSharing } from '../../copy.js'
import { HR_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const cartaConfig = {
  marketplaceCard: {
    Logo,
    integrationName: 'Carta',
    integrationDescription: t('Automatically sync employee information from Justworks to Carta'),
    integrationCategory: t('Equity management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'carta',
  },
  pdp: {
    headerDescription: t(
      'From first check to IPO, over 40,000 companies use Carta to fundraise, issue equity, and stay compliant.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      getLegalDisclaimerFn: genGetLegalDisclaimerSharing(
        t('Go to Carta <strong>Settings>Integrations</strong> to get started.')
      ),
      setupLink: {
        url: 'https://login.app.carta.com/credentials/login',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://login.app.carta.com/credentials/login',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Carta in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/27712644167579',
          Icon: MdiExternalLink,
          target: '_blank',
        },
        {
          title: t('Learn more about Carta'),
          url: 'https://www.carta.com/',
          Icon: MdiExternalLink,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Carta + Justworks',
        subheader: t(
          'The Carta integration enables you to view salary and equity data in one place. It also streamlines HR processes, allowing you to track stakeholder changes, automatically sync records, and unlock the power of Carta Total Compensation.'
        ),
        list: [
          {
            summary: t('Eliminate double entry'),
            detail: t(
              'Save time with a single source of truth for employee information like terminations or address changes.'
            ),
          },
          {
            summary: t('Simplify reconciliation'),
            detail: t(
              'Justworks data syncs to Carta daily. If there’s a discrepancy between payroll and equity data, Carta will flag it and make it easy for you to reconcile.'
            ),
          },
          {
            summary: t('Calculate taxes with ease'),
            detail: t(
              'Easily understand employees’ tax impact with salary, location, and equity information in one place.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Carta?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Carta will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('First Name'),
                t('Last Name'),
                t('Work Email'),
                t('Date of Birth'),
                t('Home address'),
                t('Work address'),
                t('Job Title'),
                t('Employment Type'),
                t('Start Date'),
                t('Termination Date'),
                t('Compensation amounts'),
                t('Compensation currency'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Carta'),
              detail: t('Carta users need <b>Make changes to company account information</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}
