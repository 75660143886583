// @ts-check
import HeroImg from '@/assets/integrations/hero/culture-amp-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/culture-amp-horizontal.svg'
import Logo from '@/assets/integrations/logo/culture-amp-logo.svg'
import { t } from '@/i18n.js'
import MdiExternalLink from '~icons/mdi/external-link'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { genGetLegalDisclaimerSharing } from '../../copy.js'
import { HR_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const cultureAmpConfig = {
  marketplaceCard: {
    Logo,
    integrationName: 'Culture Amp',
    integrationDescription: t('Automatically sync employee information from Justworks to Culture Amp'),
    integrationCategory: t('Performance management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'culture_amp',
  },
  pdp: {
    headerDescription: t(
      'Get trusted data, tools, and partnership on one platform to make the best people decisions to improve employee engagement, performance, and development.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      getLegalDisclaimerFn: genGetLegalDisclaimerSharing(
        t('Go to Culture Amp <strong>Settings>Data Integrations</strong> to get started.')
      ),
      setupLink: {
        url: 'https://id.cultureamp.com/app/auth',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://id.cultureamp.com/app/auth',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Culture Amp in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/27712930708763',
          Icon: MdiExternalLink,
          target: '_blank',
        },
        {
          title: t('Learn more about Culture Amp'),
          url: 'https://www.cultureamp.com/',
          Icon: MdiExternalLink,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Culture Amp + Justworks',
        subheader: t(
          'Import employee data directly from Justworks to Culture Amp. The setup process is straightforward and can be completed quickly without technical support.'
        ),
        list: [
          {
            summary: t('Automated account management'),
            detail: t(
              'Manage employee information in Justworks, while this integration seamlessly provisions and deprovisions users to their Culture Amp accounts.'
            ),
          },
          {
            summary: 'Daily data-syncing',
            detail: t(
              'Eliminate manual work while saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to Culture Amp every 24 hours, ensuring accurate, real-time information.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Culture Amp?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Culture Amp will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('First Name'),
                t('Preferred Name'),
                t('Last Name'),
                t('Work Email'),
                t('Phone Number'),
                t('Home location'),
                t('Work Location'),
                t('Manager'),
                t('Start date'),
                t('Employment status'),
                t('Hire date'),
                t('Termination date'),
                t('Remote data'),
                t('Department'),
                t('Gender'),
                t('Ethnicity '),
                t('Date of birth'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Culture Amp'),
              detail: t('Culture Amp users need <b>Account Admins</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}
