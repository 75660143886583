<template>
  <div class="workspace-item">
    <component :is="Link" :[address]="to" :class="{ 'router-link-active': isActive }">
      <Row class="workspace-item-row">
        <slot />

        <div v-auto-animate style="margin-left: auto">
          <IconCaretRight v-if="caret" class="caret" color="var(--color-text-secondary)" />
          <h4 v-else-if="alertCount" class="alert-badge">{{ alertCount }}</h4>
        </div>
      </Row>
    </component>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { Row } from '@justworkshr/alma'
import PageLink from '@/components/PageLink.vue'
import IconCaretRight from '~icons/ph/caret-right'

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  caret: {
    type: Boolean,
    default: false,
  },
  alertCount: {
    type: Number,
    default: 0,
  },
  name: {
    type: String,
    default: '',
  },
})

const route = useRoute()

const isActive = computed(() => route.meta.workspace === props.name)
const Link = computed(() => (props.to.startsWith('http') ? 'a' : PageLink))
const address = computed(() => (props.to.startsWith('http') ? 'href' : 'to'))
</script>

<style scoped>
.workspace-item {
  display: flex;
  align-items: center;
  transition: background-color 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1;
  border-radius: var(--border-radius);
  white-space: nowrap;

  --nav-active-background-color: var(--color-gray-200);
}

.dark .workspace-item {
  --nav-active-background-color: var(--color-navy-400);
}

.workspace-item :deep(a) {
  padding: 0.75rem var(--space-md);
  display: block;
  width: 100%;
  transition: background-color 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1;
  border-radius: var(--border-radius);
  white-space: nowrap;
}

.workspace-item :deep(*):focus-visible {
  outline-offset: -2px;
}

.workspace-item :deep(*):hover {
  text-decoration: none;
  background-color: transparent;
}

.workspace-item :deep(a):not(.router-link-active):hover {
  background-color: var(--color-nav-hover);
  cursor: pointer;
}

.workspace-item-row {
  row-gap: 0.75rem;
}

:deep(.icon):not(.caret) {
  font-size: var(--text-lg);
  color: var(--color-text-secondary);
}

:deep(.router-link-active),
:deep(.router-link-active):hover {
  position: relative;
  background-color: var(--nav-active-background-color);
  color: var(--color-accent);
  font-weight: bold;

  :deep(.icon) {
    color: var(--color-accent);
  }
}

.alert-badge {
  background-color: var(--color-accent);
  color: var(--color-button-text);
  font-weight: var(--font-weight-oatley-bold);
  border-radius: var(--radius-xs);
  padding: 0.125rem 0.438rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0.875rem;
  font-size: var(--text-xs);
  margin-bottom: 0;
}
</style>
