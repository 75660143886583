import {
  integrationPEORouteRedirects,
  integrationPEORoutePermissions,
} from '@/pages/integrations/internals/data/configs/config.js'

const PAYROLL_ROUTE_NAME_TO_PEO_REDIRECT = {
  'account-documents': '/documents',
  'account-settings': '/account_settings',
  'account-time-off': '/employee_pto',
  'admin-trainings': '/admin/trainings',
  'bulk-actions': '/bulk_actions',
  'cf-analytics-insights': '/cf/analytics/insights',
  'cf-analytics-reporting': '/cf/analytics/reporting',
  'cf-integration-marketplace': '/cf/integration-marketplace',
  'cf-pay-transactions': '/cf/pay-transactions',
  'company-calendar': '/calendar',
  'company-company_id-benefits': '/benefits/view',
  'company-company_id-documents': '/admin/documents',
  'company-company_id-invite-new': '/cf/invitation-center#employees',
  'company-company_id-settings': '/company',
  'company-company_id-time-off': '/pto/requests',
  'company-company_id-timecards': '/hourly',
  'directory-org_chart': '/directory/org_chart',
  'help-hr_resources': '/help/hr_resources',
  'international-solutions-overview': '/cf/international-solutions/overview',
  'manage-company_id-contractors': '/members/contractors',
  'manage-company_id-employees': '/members/employees',
  'manage-company_id-third-party-admins': '/cf/firms',
  'member-member_id': '/members/member_id',
  'members-vendors': '/members/vendors',
  'onboarding-confirm_your_plan': '/onboarding/confirm_your_plan',
  'payments-company_id-payrolls': '/invoices',
  'payments-payments-center': '/masspay',
  'separation-notice-all-state-requirements': '/cf/team-management/separation-notice/all-state-requirements',
  back_to_internal: '/back_to_internal',
  company_benefits_overviews: '/benefits/company_benefits_overviews/index?source=sidenav',
  custom_member_fields: '/company/custom_member_fields',
  dashboard: '/dashboard',
  enter_demo_mode: '/enter_demo_mode/redirect',
  eoy: '/eoy',
  health_insurance_learn_page: '/health_insurance_learn_page',
  help: '/help',
  internal: '/internal',
  my_benefits: '/my_benefits',
  permissions: '/permissions',
  refer: '/refer',
  training: '/training',
  vouchers: '/vouchers',
  'company-company_id-benefits-classes-master-policy-learn': '/benefits/setup/benefits_class/index',
  'company-company_id-benefits-classes-master-policy-setup': '/benefits/setup/benefits_class/define_classes',
  'company-company_id-benefits-health-master-policy-pick-plans-coverage_start_date-benefit_type':
    '/benefits/setup/coverage_start_date/policy/benefit_type/plans',
  'company-company_id-benefits-health-master-policy-contributions-benefit_type-benefits_class_id':
    '/benefits/setup/contribution/benefit_type/benefits_class_id',
  'company-company_id-benefits-health-plan-selection-master-policy-review': '/benefits/setup/review',
  ...integrationPEORouteRedirects(),
}

export const PEO_ROUTE_PERMISSIONS = {
  '/admin/trainings': ['manage_employees'],
  '/bulk-actions': ['manage_employees'],
  '/cf/analytics/insights': ['view_invoices_reporting'],
  '/cf/analytics/reporting': ['view_invoices_reporting'],
  '/cf/integration-marketplace': ['edit_company_settings'],
  '/cf/pay-transactions': ['make_payments'],
  '/separation-notice/all-state-requirements': ['manage_employees'],
  '/custom_member_fields': ['manage_employees'],
  '/members/vendors': ['manage_employees'],
  company_benefits_overviews: ['manage_company_benefits'],
  ...integrationPEORoutePermissions(),
}

export function resolvePEORedirectPath(to) {
  function normalizeRouteName(to) {
    if (to.name === 'all') {
      return to.path.replace(/\//g, '-').replace(/^-/, '')
    }

    return to.name
  }

  const normalizedRouteName = normalizeRouteName(to)
  let route = PAYROLL_ROUTE_NAME_TO_PEO_REDIRECT[normalizedRouteName]

  if (!route && to.redirectedFrom) {
    route = PAYROLL_ROUTE_NAME_TO_PEO_REDIRECT[to.redirectedFrom.name]
  }

  if (!route) return '/dashboard'

  for (const key in to.params) {
    if (key === 'all') continue
    route = route.replace(key, to.params[key])
  }

  if (to.query && Object.keys(to.query).length) {
    const query = new URLSearchParams(to.query)
    route += `?${query.toString()}`
  }

  return route
}

export function buildPEOFlipToPath(path, companyID) {
  const encodedPathParam = encodeURIComponent(import.meta.env.VITE_APP_CLOCKWORK_HOST + path)
  return `/flip_to_any_id/${companyID}?path=${encodedPathParam}`
}
