<template>
  <footer>
    <div class="legal">
      <div class="copyright">
        <div>© 2013 &ndash; {{ year }} Justworks, Inc.</div>
      </div>

      <ul class="links">
        <li>
          <PageLink class="link" to="/privacy-policy">{{ $t('Privacy') }}</PageLink>
        </li>
        <li>
          <PageLink class="link" to="/terms-of-use">{{ $t('Terms') }}</PageLink>
        </li>
        <li>
          <button v-if="featureIsOn('customer_feedback')" class="link btn-text" name="feedback">
            {{ $t('Leave feedback') }}
          </button>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue'
import PageLink from '@/components/PageLink.vue'
import { featureIsOn } from '@/features.js'

const year = ref(new Date().getFullYear())
</script>

<style scoped>
.legal {
  font-size: var(--text-sm);
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: var(--space-md);
  color: var(--color-text-secondary);

  @media (--tablet) {
    grid-template-columns: 1fr 120px;
    gap: var(--space-lg);
  }
}

ul.links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: var(--space-md);

  @media (--tablet) {
    align-items: flex-start;
    justify-content: flex-end;
  }
}

li > .link {
  color: var(--color-secondary-text);
}
</style>
