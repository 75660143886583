// @ts-check
import logoImg from '@/assets/integrations/logo/netsuite-logo.svg'
import { t } from '@/i18n.js'
import { ACCOUNTING_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const netsuiteConfig = {
  marketplaceCard: {
    Logo: logoImg,
    integrationName: 'NetSuite',
    integrationDescription: t('Seamlessly connect business systems for streamlined operations'),
    integrationCategory: t('Accounting'),
    requiredPermissions: ACCOUNTING_PERMISSIONS.value,
    slug: 'netsuite',
    getPdpLink: () => '/netsuite/settings',
  },
}
