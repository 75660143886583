// @ts-check
import HeroImg from '@/assets/integrations/hero/ramp-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/ramp-horizontal.svg'
import Logo from '@/assets/integrations/logo/ramp-logo.svg'
import { t } from '@/i18n.js'
import MdiExternalLink from '~icons/mdi/external-link'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const rampConfig = {
  marketplaceCard: {
    Logo,
    integrationName: 'Ramp',
    integrationDescription: t('Automatically sync employee information from Justworks to Ramp'),
    integrationCategory: t('Expense management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'ramp',
  },
  pdp: {
    headerDescription: t(
      'Ramp is the ultimate platform for modern finance teams. Automate your finance operations with Ramp’s all-in-one solution for corporate cards, expense management, travel and more.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://app.ramp.com/settings/integrations/hris',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://app.ramp.com/settings/integrations/hris',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Ramp in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/27712846224539',
          Icon: MdiExternalLink,
          target: '_blank',
        },
        {
          title: t('Learn more about Ramp'),
          url: 'https://ramp.com/',
          Icon: MdiExternalLink,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Ramp + Justworks',
        subheader: t(
          'Ramp integrates with Justworks to help you simplify onboarding, issue employee benefits, and control spend at scale.'
        ),
        list: [
          {
            summary: t('Automated account management'),
            detail: t(
              'Manage employee information in Justworks, and let this integration seamlessly provision and deprovision team members to their Ramp accounts.'
            ),
          },
          {
            summary: t('Efficient data-syncing'),
            detail: t(
              'Eliminate manual work, saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to Ramp every 24 hours, ensuring accurate, up-to-date information.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Ramp?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Ramp will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('Name'),
                t('Email'),
                t('Direct Manager'),
                t('Department'),
                t('Location'),
                t('Employment Status'),
                t('Start Date'),
                t('Termination Date'),
                t('Company Name'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Ramp'),
              detail: t('Ramp users need <b> Admins and Owners</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}
