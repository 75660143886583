// @ts-check
import logoImg from '@/assets/integrations/logo/xero-logo.svg'
import { t } from '@/i18n.js'
import { ACCOUNTING_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const xeroConfig = {
  marketplaceCard: {
    Logo: logoImg,
    integrationName: 'Xero',
    integrationDescription: t('Send all your paid invoices to Xero to streamline accounting processes'),
    integrationCategory: t('Accounting'),
    requiredPermissions: ACCOUNTING_PERMISSIONS.value,
    slug: 'xero',
    getPdpLink: () => '/xero/v3/settings',
  },
}
