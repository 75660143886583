<template>
  <Transition :name="subRouteTransitionName" mode="out-in">
    <CompanySubNav v-if="showCompanySubNav" :company_id v-bind="$attrs" />

    <YouSubNav v-else-if="showYouSubNav" v-bind="$attrs" />

    <Stack v-else gap="xs" class="nav-stack" v-bind="$attrs">
      <WorkspaceLink :to="`/dashboard`" :alert-count="companyAlertCount" name="home">
        <IconHouseLine />
        {{ $t('Home') }}
      </WorkspaceLink>

      <WorkspaceLink
        v-if="!isTerminated"
        :to="showNavManage ? `/manage/${company_id}/employees` : `/company/${company_id}/directory`"
        name="people"
      >
        <IconUsers />
        {{ $t('People') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="!isTerminated" :to="`/company/calendar`" name="calendar">
        <IconCalendar />
        {{ $t('Calendar') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="showNavPayments" :to="`/payments/${company_id}/payrolls`" name="pay">
        <IconInvoice />
        {{ $t('Pay') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="showNavPayments" :to="`/payments/${company_id}/timecards`" name="timecards">
        <IconClock />
        {{ $t('Timecards') }}
      </WorkspaceLink>

      <WorkspaceLink :to="`/company/${company_id}/benefits/payroll`" name="benefits">
        <IconHeart />
        {{ $t('Benefits') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="showNavPayments" :to="`/company/${company_id}/reports`" name="reporting">
        <IconChart />
        {{ $t('Reporting') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="showNavCompany" :to="`/company/${company_id}/documents`" name="documents">
        <IconDocument />
        {{ $t('Documents') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="showNavMember" to="/account/settings" caret>
        <IconUser />
        {{ $t('Your stuff') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="showNavMember" :to="`/company/${company_id}/settings`" caret>
        <IconGear />
        {{ $t('Company settings') }}
      </WorkspaceLink>

      <FlexSpace />

      <Stack gap="sm">
        <WorkspaceLink to="https://help.justworks.com/hc/en-us">
          <IconQuestion />
          {{ $t('Help') }}
        </WorkspaceLink>

        <WorkspaceLink v-if="mobile" :to="`/logout`">
          <IconSignOut />
          {{ $t('Log out') }}
        </WorkspaceLink>
      </Stack>
    </Stack>
  </Transition>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { FlexSpace, Stack } from '@justworkshr/alma'
import WorkspaceLink from '@/components/WorkspaceLink.vue'
import useBreakpoint from '@/use/useBreakpoint.js'
import useCompanyAlerts from '@/use/useCompanyAlerts.js'
import useNavPermissions from '@/use/useNavPermissions.js'
import { viewer, isTerminated } from '@/viewer.js'
import IconCalendar from '~icons/ph/calendar-blank'
import IconChart from '~icons/ph/chart-bar'
import IconClock from '~icons/ph/clock'
import IconDocument from '~icons/ph/file'
import IconGear from '~icons/ph/gear'
import IconHeart from '~icons/ph/heart'
import IconHouseLine from '~icons/ph/house-line'
import IconInvoice from '~icons/ph/invoice'
import IconQuestion from '~icons/ph/question'
import IconSignOut from '~icons/ph/sign-out'
import IconUser from '~icons/ph/user'
import IconUsers from '~icons/ph/users'
import CompanySubNav from './CompanySubNav.vue'
import YouSubNav from './YouSubNav.vue'

const emits = defineEmits(['route-change'])

const subRouteTransitionName = ref()

const { showNavPayments, showNavManage, showNavCompany, showNavMember } = useNavPermissions()

const { companyAlertCount } = useCompanyAlerts()

const { mobile } = useBreakpoint()
const route = useRoute()

const company_id = computed(() => viewer.value.activeCompany.company_id)

const showCompanySubNav = computed(() => route.meta.subnav === 'company' && !isTerminated.value)
const showYouSubNav = computed(() => route.meta.subnav === 'you')

watch(
  () => route.fullPath,
  (newPath, oldPath) => {
    const newPathParts = newPath.split('/')
    const oldPathParts = oldPath.split('/')

    if (newPathParts.length > oldPathParts.length) {
      subRouteTransitionName.value = 'slide-in-right'
    } else {
      subRouteTransitionName.value = 'slide-in-left'
    }

    emits('route-change', route.name)
  }
)
</script>

<style scoped>
.nav-stack {
  height: 100%;

  & > * {
    flex-shrink: 0;
  }
}
</style>
