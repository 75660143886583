import { t } from '@/i18n.js'

export function getLegalDisclaimerSending(partnerName, companyName) {
  return t(
    'By setting up the integration with {partnerName}, on behalf of {companyName}, you acknowledge and agree that Justworks will send certain { companyName } data, including personal information, to { partnerName }, and that { companyName } is responsible for obtaining any consent required from suchdata subjects.',
    { partnerName, companyName }
  )
}

export function getLegalDisclaimerReceiving(partnerName, companyName) {
  return t(
    'By setting up the integration with {partnerName}, on behalf of {companyName}, you acknowledge and agree that Justworks will receive certain { companyName } data, including personal information, from { partnerName }, and that { companyName } is responsible for obtaining any consent required from such data subjects.',
    { partnerName, companyName }
  )
}

export function getLegalDisclaimerSharing(partnerName, companyName, customContinue) {
  const defaultContinue = t('Continue to {partnerName} to get started.', { partnerName })
  const continueText = customContinue || defaultContinue
  return t(
    'By integrating with {partnerName}, you agree that Justworks will share certain {companyName} data, including personal information, with {partnerName}. {companyName} is responsible for obtaining any required consent from data subjects. <br /> <br /> {continueText}',
    { partnerName, companyName, continueText }
  )
}

export function genGetLegalDisclaimerSharing(customContinue) {
  return (partnerName, companyName) => getLegalDisclaimerSharing(partnerName, companyName, customContinue)
}

export function getPdpPermissionNotice(permissions, firstAdminName = null) {
  const adminContact = firstAdminName || 'your Justworks admin'
  return t(
    `<span>
      Only Justworks admins with<strong> {formattedPermissions} </strong>
      permissions can set up or modify this integration. Contact
      <strong> {adminContact} </strong> to request access.
    </span>`,
    { formattedPermissions: permissions.listCopy, adminContact }
  )
}

export function getIntegrationBadgeData(status) {
  if (status === 'active') return { color: 'green', copy: t('Connected') }
  if (status === 'error') return { color: 'red', copy: t('Error') }
  if (status === 'pending') return { color: 'blue', copy: t('Pending') }
  return null
}

export function getSolutionBadgeData(beta) {
  return beta ? { color: 'purple', copy: t('Beta') } : null
}
