<template>
  <div class="company-select-wrapper">
    <select
      class="company-select"
      :value="viewer.activeCompany.company_id"
      :aria-label="$t('Select company')"
      @change="onChangeCompanies"
    >
      <option
        v-for="[company_id, { dba, entity_name, origin }] in Object.entries(viewer.companies)"
        :key="company_id"
        :value="company_id"
      >
        {{ dba || entity_name }}
        <span v-if="origin === 'peo'">— PEO</span>
      </option>
      <template v-if="showAddNewCompanyOption">
        <option disabled>—</option>
        <option value="/company/new">{{ $t('Add company') }}</option>
      </template>
    </select>
    <IconCaretUpDown class="icon select-caret" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { buildPEOFlipToPath } from '@/data/peoRedirects.js'
import { viewer, viewerCan } from '@/viewer.js'
import IconCaretUpDown from '~icons/ph/caret-up-down'

const router = useRouter()

const showAddNewCompanyOption = computed(() => viewerCan('edit_company_settings'))

function onChangeCompanies(e) {
  e.target.blur()
  const companyId = e.target.value

  if (companyId === '/company/new') {
    router.push('/company/new')
    return
  }

  if (isPeoCompany(companyId)) {
    window.location.assign(import.meta.env.VITE_APP_CLOCKWORK_HOST + buildPEOFlipToPath('/dashboard', companyId))
    return
  }

  router.push(`/dashboard?co=${companyId}`)
}

function isPeoCompany(companyId) {
  return viewer.value.companies[companyId]?.origin === 'peo'
}
</script>

<style scoped>
.company-select-wrapper {
  position: relative;
  flex-grow: 1;
}

.company-select {
  padding-right: 2rem;
  height: unset;
  background: unset;
  border: unset;
  padding-block: unset;
  padding-left: 0;
  text-overflow: ellipsis;
  width: 100%;
}

.select-caret {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  pointer-events: none;
  font-size: 1.25rem;
}
</style>
