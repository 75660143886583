<script setup>
import { computed } from 'vue'
import { Stack } from '@justworkshr/alma'
import { integrationConfigs } from '../../../data/configs/config.js'

const props = defineProps({
  partner_slug: {
    type: String,
    required: true,
  },
})

const tabContent = computed(() => integrationConfigs[props.partner_slug].pdp.infoTabsContent.overviewTab)
</script>

<template>
  <Stack>
    <h2>{{ tabContent.header }}</h2>
    <div>{{ tabContent.subheader }}</div>

    <hr />
    <div v-for="item in tabContent.list" :key="item.detail">
      <h3>{{ item.summary }}</h3>
      <div>{{ item.detail }}</div>
    </div>
  </Stack>
</template>
