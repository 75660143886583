// @ts-check
import HeroImg from '@/assets/integrations/hero/15five-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/15five-horizontal.svg'
import Logo from '@/assets/integrations/logo/15five-logo.svg'
import { t } from '@/i18n.js'
import MdiExternalLink from '~icons/mdi/external-link'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const fifteenfiveConfig = {
  marketplaceCard: {
    Logo,
    integrationName: '15Five',
    integrationDescription: t('Automatically sync employee information from Justworks to 15Five'),
    integrationCategory: t('Performance management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'fifteenfive',
  },
  pdp: {
    headerDescription: t(
      '15Five is the performance management platform that creates effective managers, highly engaged employees, and top-performing organizations.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://my.15five.com/integrations/',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://my.15five.com/integrations/',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('15Five in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/27713067210523',
          Icon: MdiExternalLink,
          target: '_blank',
        },
        {
          title: t('Learn more about 15Five'),
          url: 'https://www.15five.com/',
          Icon: MdiExternalLink,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: '15Five + Justworks',
        subheader: t(
          'Unlock the power of seamless data connectivity with the Justworks 15Five integration. Your Justworks employee data will be automatically synced, setting you up for success right away. '
        ),
        list: [
          {
            summary: t('Automated account management'),
            detail: t(
              'Manage employee information in Justworks, and let this integration seamlessly provision and deprovision team members to their 15Five accounts.'
            ),
          },
          {
            summary: t('Efficient data-syncing'),
            detail: t(
              'Eliminate manual work, saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to 15Five every 24 hours, ensuring accurate, up-to-date information.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with 15Five?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, 15Five will automatically pull in the following data from Justworks:'
              ),
              sharedData: [],
            },
            {
              header: t('Standard information'),
              sharedData: [
                t('First Name'),
                t('Preferred Name'),
                t('Last Name'),
                t('Email'),
                t('Job Title'),
                t('Location'),
                t('Employee ID'),
                t('Status (Is active Y/N)'),
                t('Hire Date'),
                t('Start Date'),
                t('Manager/Reviewer'),
                t('Termination Date'),
              ],
            },
            {
              header: t('Demographic data (if enabled)'),
              sharedData: [t('Salary'), t('Gender'), t('Ethnicity'), t('Birth Date')],
            },
            {
              header: t('Group Types / Groups'),
              sharedData: [t('Departments')],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In 15Five'),
              detail: t('15Five users need <b>Account admin</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}
