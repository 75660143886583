// @ts-check
import { brexConfig } from './brex/config'
import { cartaConfig } from './carta/config'
import { cultureAmpConfig } from './culture-amp/config'
import { fifteenfiveConfig } from './fifteenfive/config'
import { greenhouseConfig } from './greenhouse/config'
import { jazzHrConfig } from './jazzhr/config'
import { leverConfig } from './lever/config'
import { netsuiteConfig } from './netsuite/config'
import { quickbooksPayrollConfig } from './quickbooks/payroll_config'
import { quickbooksPEOConfig } from './quickbooks/peo_config'
import { rampConfig } from './ramp/config'
import { sageIntacctConfig } from './sage-intacct/config'
import { trainualConfig } from './trainual/config'
import { vantaConfig } from './vanta/config'
import { xeroConfig } from './xero/config'

/** @type {Object<string, IntegrationConfig>} */

const clockworkConfigs = {
  [greenhouseConfig.marketplaceCard.slug]: greenhouseConfig,
  [netsuiteConfig.marketplaceCard.slug]: netsuiteConfig,
  [xeroConfig.marketplaceCard.slug]: xeroConfig,
  [quickbooksPEOConfig.marketplaceCard.slug]: quickbooksPEOConfig,
  [leverConfig.marketplaceCard.slug]: leverConfig,
  [jazzHrConfig.marketplaceCard.slug]: jazzHrConfig,
  [sageIntacctConfig.marketplaceCard.slug]: sageIntacctConfig,
}

const neonConfigs = {
  [quickbooksPayrollConfig.marketplaceCard.slug]: quickbooksPayrollConfig,
  [rampConfig.marketplaceCard.slug]: rampConfig,
  [brexConfig.marketplaceCard.slug]: brexConfig,
  [cartaConfig.marketplaceCard.slug]: cartaConfig,
  [fifteenfiveConfig.marketplaceCard.slug]: fifteenfiveConfig,
  [vantaConfig.marketplaceCard.slug]: vantaConfig,
  [trainualConfig.marketplaceCard.slug]: trainualConfig,
  [cultureAmpConfig.marketplaceCard.slug]: cultureAmpConfig,
}

export const integrationConfigs = {
  ...clockworkConfigs,
  ...neonConfigs,
}

/** @returns {Object<string, string>} */
export function integrationPEORouteRedirects() {
  return Object.values(clockworkConfigs).reduce((acc, config) => {
    const link = config.marketplaceCard.getPdpLink()
    const key = link.startsWith('/') ? link.substring(1) : link
    acc[key.replaceAll('/', '-')] = link
    return acc
  }, {})
}

/** @returns {Object<string, string[]>} */
export function integrationPEORoutePermissions() {
  return Object.values(clockworkConfigs).reduce((acc, config) => {
    acc[config.marketplaceCard.getPdpLink()] = config.marketplaceCard.requiredPermissions.slugs
    return acc
  }, {})
}
