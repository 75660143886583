import { computed } from 'vue'
import { t } from '@/i18n.js'

export const INTEGRATION_PERMISSIONS = computed(() => ({
  // HRIS
  CHANGE_EMPLOYEES: { slug: 'change_employees', name: t('Manage employees') }, // Clockwork
  MANAGE_EMPLOYEES: { slug: 'manage_employees', name: t('Manage employees') }, // Payroll
  EMPLOYEE_INFO_LIMITED: { slug: 'employee_info_limited', name: t('View basic employee information') }, // Clockwork
  EMPLOYEE_INFO_FULL: { slug: 'employee_info_full', name: t('View all employee information') }, // Clockwork
  VIEW_BASIC_EMPLOYEE_INFORMATION: {
    slug: 'view_basic_employee_information',
    name: t('View basic employee information'),
  }, // Payroll
  VIEW_ALL_EMPLOYEE_INFORMATION: { slug: 'view_all_employee_information', name: t('View all employee informatio') }, // Payroll

  // Benefits
  BENEFITS: { slug: 'benefits', name: t('Manage company benefits') }, // Clockwork
  MANAGE_COMPANY_BENEFITS: { slug: 'manage_company_benefits', name: t('Manage company benefits') }, // Payroll

  //TIMECARDS
  TIMECARDS: { slug: 'timecards', name: t('Manage all timecards') }, // Clockwork
  MANAGE_ALL_TIMECARDS: { slug: 'manage_all_timecards', name: t('Manage all timecards') }, // Payroll

  // TIME OFF
  ADMIN_PTO: { slug: 'admin_pto', name: t('Manage all paid time off') }, // Clockwork
  MANAGE_ALL_PAID_TIME_OFF: { slug: 'manage_all_timecards', name: t('Manage all paid time off') }, // Payroll

  // DOCUMENTS
  DOC_CENTER_ADMIN: { slug: 'doc_center_admin', name: t('Upload & view all documents') }, //Clockwork
  VIEW_ADMIN_DOCUMENTS: { slug: 'view_admin_documents', name: t('Upload & view all documents') }, // Payroll

  // EMPLOYEE PERMISSIONS
  GRANT_PERMISSIONS: { slug: 'grant_permissions', name: t('Edit employee permissions') }, //Clockwork
  EDIT_EMPLOYEE_PERMISSIONS: { slug: 'edit_employee_permissions', name: t('Edit employee permissions') }, // Payroll

  // CONTRACTS AND VENDORS
  PAY_CONTRACTORS_VENDORS: { slug: 'pay_contractors_vendors', name: t('Pay contractors & vendors') }, //Clockwork
  PAY_CONTRACTORS_AND_VENDORS: { slug: 'pay_contractors_and_vendors', name: t('Pay contractors & vendors') }, // Payroll

  // PAY
  MAKE_PAYMENTS: { slug: 'make_payments', name: t('Make payments') }, // Clockwork & Payroll
  PAY_METRICS: { slug: 'pay_metrics', name: t('View invoices & reporting') }, // Clockwork
  VIEW_INVOICES_REPORTING: { slug: 'view_invoices_reporting', name: t('View invoices & reporting') }, // Payroll
  EXPENSES: { slug: 'expenses', name: t('Manage expenses') }, // Clockwork
  // COMPANY
  CHANGE_COMPANY_SETTINGS: { slug: 'change_company_settings', name: t('Edit company settings') }, // Clockwork
  EDIT_COMPANY_SETTINGS: { slug: 'edit_company_settings', name: t('Edit company settings') }, // Payroll
}))

export const ACCOUNTING_PERMISSIONS = computed(() => {
  const list = [
    INTEGRATION_PERMISSIONS.value.MAKE_PAYMENTS,
    INTEGRATION_PERMISSIONS.value.PAY_METRICS,
    INTEGRATION_PERMISSIONS.value.VIEW_INVOICES_REPORTING,
    INTEGRATION_PERMISSIONS.value.EXPENSES,
  ]
  return {
    slugs: permissionsSlugs(list),
    copy: t('{accountingPermissionsList}', { accountingPermissionsList: permissionsListCopy(list) }),
  }
})

export const HR_PERMISSIONS = computed(() => {
  const list = [INTEGRATION_PERMISSIONS.value.CHANGE_EMPLOYEES, INTEGRATION_PERMISSIONS.value.MANAGE_EMPLOYEES]
  return {
    slugs: permissionsSlugs(list),
    copy: t('{hrPermissionsList}', { hrPermissionsList: permissionsListCopy(list) }),
  }
})

function permissionsNames(permissions) {
  const res = permissions.map((permission) => permission.name)
  return res
}

function permissionsSlugs(permissions) {
  const res = permissions.map((permission) => permission.slug)
  return res
}

function permissionsListCopy(permissions) {
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'disjunction',
  })
  const permissionsNamesWithoutDuplicates = [...new Set(permissionsNames(permissions))]
  return formatter.format(permissionsNamesWithoutDuplicates)
}

export function checkHasPermissions(permissions, requiredPermissionsSet, requiredAll = true) {
  const requiredPermissions = permissionsSlugs(requiredPermissionsSet)
  if (requiredAll) {
    return requiredPermissions.every((permission) => permissions.includes(permission))
  }
  return requiredPermissions.some((permission) => permissions.includes(permission))
}

const permissionsBySlug = computed(() =>
  Object.values(INTEGRATION_PERMISSIONS.value).reduce((acc, permission) => {
    acc[permission.slug] = permission
    return acc
  }, {})
)

function slugListToPermissionsList(slugList) {
  const formattedSlugList = slugList?.map(
    // get the permission object from the slug, or create a new one with the slug formatted into the name - e.g. 'change_employees' -> 'change employees'
    // this autoformatting should be a last resort, and should be replaced with a permission definition in INTEGRATION_PERMISSIONS
    (slug) => {
      if (permissionsBySlug.value[slug]) {
        return permissionsBySlug.value[slug]
      }

      const slugName = slug.replace(/_/g, ' ').toLowerCase()
      const formattedSlugName = slugName.charAt(0).toUpperCase() + slugName.slice(1)

      return {
        slug,
        name: formattedSlugName,
      }
    }
  )
  return formattedSlugList
}

export function permissionsListCopyBySlug(permissionSlugs) {
  return permissionsListCopy(slugListToPermissionsList(permissionSlugs))
}
