import { datadogRum } from '@datadog/browser-rum'
import { viewer } from '@/viewer.js'

/** @typedef {enrollmentTask | agreementTask | onboardingTask} customerioStage */

/** @type {customerioStage[]} */
const customerioStages = [
  'general_company_info',
  'explore_benefits',
  'self_onboarded',
  'invite_team',
  'state_tax_info',
  'bank_account',
  'bank_account_verify',
  'manage_workers_comp',
  'csa',
  'access_dashboard',
  'key_company_contacts',
  'payroll_history_info',
]

/** @param {customerioStage} task */
export default function postTaskToCustomerIO(task) {
  if (!customerioStages.includes(task)) return

  const payload = {
    email: viewer.value.email_home,
    first_name: viewer.value.activeMembership.member.first_name,
    last_name: viewer.value.activeMembership.member.last_name,
    company: {
      name: viewer.value.activeMembership.company.entity_name,
    },
    stage: task,
  }

  // no need to await this "fire and forget" request
  fetch(import.meta.env.VITE_APP_CUSTOMERIO_EVENTS_URL, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch((error) => {
    datadogRum.addError(error)
  })
}

/** @typedef {'basic_to_plus_pdp_page_view' | 'basic_to_plus_pdp_banner_cta_click'} customerioEvent */

/** @type {customerioEvent[]} */
const customerioEvents = ['basic_to_plus_pdp_pageview', 'basic_to_plus_pdp_banner_cta_click']

/**
 * Sends page events (page_view or cta_click) to customerIO.
 *
 * @param {string} eventName
 * @param {Object} eventData
 */
export function sendEventToCustomerIO(eventName, eventData) {
  if (!customerioEvents.includes(eventName)) return

  const payload = {
    company: {
      name: viewer.value.activeMembership.company.entity_name,
      uuid: eventData.company_uuid,
    },
    data: eventData.data,
    email: viewer.value.email_home,
    environment: import.meta.env.MODE,
    event_name: eventName,
    event_type: eventData.event_type,
    first_name: viewer.value.activeMembership.member.first_name,
    last_name: viewer.value.activeMembership.member.last_name,
    member_uuid: eventData.member_uuid,
  }

  const customerioEndpoint = getCustomerIOEndpoint(eventData.event_type)

  fetch(customerioEndpoint, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch((error) => {
    datadogRum.addError(error)
  })
}

/**
 * Returns the correct customerio endpoint depending on eventType. It must be either 'pageview' or 'cta_click'.
 *
 * @param {string} eventType
 */
function getCustomerIOEndpoint(eventType) {
  const BASE_URL = import.meta.env.VITE_APP_CUSTOMERIO_BASE_URL
  const PAGEVIEW_ENDPOINT = 'acbf80ba878c6dae'
  const CTA_CLICK_ENDPOINT = 'b8cddc508701ab24'

  switch (eventType) {
    case 'pageview':
      return BASE_URL + PAGEVIEW_ENDPOINT
    case 'cta_click':
      return BASE_URL + CTA_CLICK_ENDPOINT
  }
}
