<template>
  <div ref="workspace-item" class="workspace-item">
    <button ref="workspace-btn" class="workspace-btn btn-frameless" :class="{ active: show }" @click="show = !show">
      <slot name="button" />
    </button>

    <div
      ref="popover"
      :aria-hidden="!show"
      class="workspace-popover"
      :class="{ show, top: position === 'top', bottom: position === 'bottom' }"
    >
      <slot name="popover" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  position: {
    type: String,
    default: 'top',
    validator: (value) => ['top', 'bottom'].includes(value),
  },
  offset: {
    type: Number,
    default: 0,
  },
})

const show = ref(false)

const route = useRoute()
const popover = useTemplateRef('popover')
const btn = useTemplateRef('workspace-btn')
const item = useTemplateRef('workspace-item')

const topOffset = computed(() => `-${(btn.value?.offsetHeight + props.offset) * 2}px`)
const bottomOffset = computed(() => `${(btn.value?.offsetHeight + props.offset) * 2}px`)

onClickOutside(
  popover,
  () => {
    show.value = false
  },
  { ignore: [btn, item] }
)

watch(
  () => route.fullPath,
  () => {
    show.value = false
  }
)
</script>

<style scoped>
.workspace-item {
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1;
  border-radius: var(--border-radius);
  white-space: nowrap;
  padding: var(--space-md);

  --nav-active-background-color: var(--color-gray-200);
}

.dark .workspace-item {
  --nav-active-background-color: var(--color-navy-400);
}

.workspace-btn {
  display: block;
  width: 100%;
  transition: background-color 0.5s;
  overflow: hidden;
  text-align: left;
  line-height: 1;
  border-radius: var(--radius-sm);

  &:hover {
    background-color: var(--color-nav-hover);
    outline: 1px solid var(--color-border);
  }
}

.workspace-btn.active {
  background-color: var(--color-nav-hover);
  outline: 1px solid var(--color-border);
}

.workspace-popover {
  background: var(--color-page-background);
  border-radius: var(--radius-sm);
  border: 1px solid var(--color-border);
  opacity: 0;
  width: 224px;
  padding: var(--space-sm) var(--space-xs);
  pointer-events: none;
  position: fixed;
  transition-property: transform, opacity, box-shadow;
  transition-duration: 0.5s;
  transition-timing-function: var(--ease-out-custom);
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.workspace-popover :slotted(> *) {
  padding: 0.75rem var(--space-md);
}

.workspace-popover :slotted(> a):hover {
  text-decoration: none;
  background-color: var(--color-nav-hover);
  border-radius: var(--radius-sm);
}

.workspace-popover.show {
  opacity: 1;
  pointer-events: all;
  box-shadow:
    4px 4px 8px rgb(28 29 31 / 20%),
    -4px -4px 8px rgb(28 29 31 / 5%);
}

.workspace-popover.show.top {
  transform: translateY(v-bind('topOffset'));
}

.workspace-popover.show.bottom {
  transform: translateY(v-bind('bottomOffset'));
}
</style>
