// @ts-check
import logoImg from '@/assets/integrations/logo/jazzhr-logo.svg'
import { t } from '@/i18n.js'
import { HR_PERMISSIONS } from '../../permissions'

/** @type {IntegrationConfig} */
export const jazzHrConfig = {
  marketplaceCard: {
    Logo: logoImg,
    integrationName: 'JazzHR',
    integrationDescription: t('Import new hires from JazzHR right into Justworks.'),
    integrationCategory: t('Hiring'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'jazzhr',
    getPdpLink: () => '/cf/integrations/jazzhr',
  },
}
