import { ref, watch } from 'vue'
import useApiFetch from '@/use/useApiFetch.js'
import { viewer, viewerCan } from '@/viewer.js'

const companyAlertCount = ref(0)

export default function useCompanyAlerts() {
  const {
    data: companyAlerts,
    get: getCompanyAlerts,
    fetching: fetchingCompanyAlerts,
  } = useApiFetch(`/data/companies/${viewer.value.activeCompany.company_id}/alerts`)

  if (viewerCan('manage_paid_time_off', 'manage_employees')) {
    getCompanyAlerts()
  }

  watch(companyAlerts, (newCompanyAlerts) => {
    companyAlertCount.value = Number(newCompanyAlerts.counts?.total)
  })

  return {
    companyAlertCount,
    companyAlerts,
    fetchingCompanyAlerts,
  }
}
